.tnt-gift-article.dropdown {
    margin-left: auto;
    text-align: right;
}
#tnt-gift-btn {
    border-radius: 20px 0 0 20px;
}
#tnt-gift-menu {
    padding: 0;
    width: 250px;
    left: auto;
    right: 0;
}
#tnt-gift-menu .gift-title {
    padding: 5px 15px;
    background-color: rgba(0,0,0,0.05);
    border-bottom: 1px solid rgba(0,0,0,0.1);
}
#tnt-gift-menu .gift-body {
    padding: 15px;
}
#tnt-gift-menu .gift-desc {
    margin-bottom: 5px;
}
#tnt-gift-menu .gift-options {
    margin: 0;
    list-style: none;
    padding: 0;
    text-align: left;
}
#tnt-gift-menu .gift-options li {
    border-bottom: 1px solid rgba(0,0,0,0.1);
}
#tnt-gift-menu .gift-options li:last-child {
    border: none;
}
#tnt-gift-menu .gift-options li a {
    padding: 8px;
    display: block;
}
#tnt-gift-menu .gift-options > li > a:hover,
#tnt-gift-menu .gift-options > li > a:focus {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
}
#tnt-gift-menu .gift-options .tnt-svg {
    margin-right: 4px;
}
#tnt-gift-menu #gift-limit {
    margin-bottom: 0;
}
#tnt-gift-menu .alert {
    margin-bottom: 15px;
}